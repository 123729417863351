<template>
  <KTCodePreview v-bind:title="'Danh sách các Form POSM'">
    <template v-slot:toolbar>
      <b-dropdown
        size="sm"
        right
        split
        variant="primary"
        @click="routeCreatePosmForm"
        v-if="checkPermissions(['POSM_FORM_INSERT', 'POSM_FORM'])"
      >
        <template v-slot:button-content>
          <div class="font-weight-bolder">
            <span> <i class="flaticon2-add-1 icon-font-size"></i>Tạo mới</span>
          </div>
        </template>
        <b-dropdown-item-button @click="routeImportExcel"
          ><i class="far fa-file-excel mr-2 text-success"></i>Import excel
        </b-dropdown-item-button>
      </b-dropdown>
    </template>
    <template v-slot:preview>
      <b-col cols="12" class="custom-card-body">
        <b-row>
          <b-col cols="3">
            <b-form-group>
              <label for="search-by-campaign"></label>
              <b-form-input
                id="search-by-campaign"
                size="sm"
                v-model="searchByCampaignInput"
                placeholder="Tìm kiếm theo Campaign"
                v-on:keyup.enter="assignFilter()"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="d-flex">
            <div class="align-self-center">
              <b-button variant="primary" size="sm" @click="assignFilter"
                >Lọc
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col></b-col>
        </b-row>
        <b-row class="pb-4">
          <b-col cols="8" class="d-flex align-items-center">
            <p class="mb-0"><strong>Tổng số</strong>: {{ total }}</p>
          </b-col>
          <b-col cols="4" class="d-flex align-items-center">
            <span class="text-nowrap">Hiển thị:</span>
            <b-form-select
              class="mr-2"
              v-model="apiParams.limit"
              :options="perPageOptions"
              size="sm"
            >
            </b-form-select>
          </b-col>
        </b-row>
        <b-table
          bordered
          :items="posmForms"
          :fields="fields"
          empty-text="Bạn chưa tạo Form nào"
          :per-page="apiParams.limit"
          class="table-vertical-center"
        >
          >
          <template #cell(timeRange)="row">
            <p>Từ: {{ row.item.timeRange[0] }}</p>
            <p>Đến: {{ row.item.timeRange[1] }}</p>
          </template>
          <template #cell(stores)="row">
            <div
              v-if="
                Array.isArray(row.item.stores) && row.item.stores.length > 0
              "
            >
              <div v-for="store in row.item.stores" :key="store.uuid">
                <p class="mb-0">{{ store.name }}</p>
                <p>Địa chỉ: {{ store.address }}</p>
              </div>
            </div>
            <p v-else>{{ row.item.stores }}</p>
          </template>
          <template #cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i class="flaticon2-settings icon-font-size pr-0"></i>
                </template>
                <b-dropdown-item
                  @click="editPOSMFormHandler(row)"
                  v-if="checkPermission('POSM_FORM_UPDATE')"
                >
                  <span>
                    <i class="flaticon2-pen icon-font-size"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="deletePOSMFormHandler(row)"
                  v-if="checkPermission('POSM_FORM_DELETE')"
                >
                  <span>
                    <i
                      class="flaticon2-rubbish-bin-delete-button icon-font-size rubbish-icon-color"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-if="total > 0"
          v-model="apiParams.page"
          :total-rows="total"
          :per-page="apiParams.limit"
          aria-controls="my-table-product"
          align="right"
        ></b-pagination>
      </b-col>
    </template>
  </KTCodePreview>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { posmFormTableFields as fields } from '@/view/pages/posm/table-setup';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import POSMFormTableData from '@/view/pages/posm/models/POSMFormTableData';
import {
  convertToNumber,
  isEqualObjectFirstLevel,
  makeToastFaile,
  makeToastSuccess,
} from '@/utils/common';
import { SUCCESS_RESPONSE_STATUS } from '@/utils/constants';
import { checkPermission, checkPermissions } from '@/utils/saveDataToLocal';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import SelectOption from '@/view/pages/posm/models/SelectOption';

export default {
  components: {
    KTCodePreview,
  },
  data() {
    return {
      fields,
      posmForms: [],
      total: 0,

      perPageOptions: [
        new SelectOption('5 dòng', 5),
        new SelectOption('10 dòng', 10),
        new SelectOption('20 dòng', 20),
        new SelectOption('30 dòng', 30),
        new SelectOption('40 dòng', 40),
        new SelectOption('50 dòng', 50),
      ],
      apiParams: {
        page: 0,
        limit: 10,
        searchByCampaign: '',
      },
      searchByCampaignInput: '',
    };
  },
  watch: {
    apiParams: {
      handler() {
        const clonedApiParams = {
          limit: this.apiParams.limit.toString(),
          page: this.apiParams.page.toString(),
          searchByCampaign: this.apiParams.searchByCampaign,
        };
        if (!isEqualObjectFirstLevel(this.$route.query, clonedApiParams)) {
          this.$router.replace({ query: this.apiParams });
          this.fetchPOSMForms();
        }
      },
      deep: true,
    },
  },
  methods: {
    checkPermissions,
    checkPermission,
    editPOSMFormHandler(row) {
      const uuid = row.item.uuid;
      this.$router.push({ name: 'posm-form-edit', params: { uuid } });
    },
    deletePOSMFormHandler(row) {
      const uuid = row.item.uuid;
      const confirmDelete = confirm('Bạn có chắc chắn muốn xóa Form này?');

      if (!confirmDelete) {
        return;
      }
      ApiService.delete(`${cmdUrl.POSMForm.root}/${uuid}`)
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            makeToastSuccess('Xoá form POSM thành công');
            this.fetchPOSMForms();
          }
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    fetchPOSMForms(params) {
      ApiService.query(cmdUrl.POSMForm.root, {
        params: params ? params : { ...this.apiParams },
      })
        .then(({ data }) => {
          this.total = data.data.count;
          this.posmForms = data.data.data.map((posmForm) => {
            const td = new POSMFormTableData(
              posmForm.uuid,
              posmForm.name,
              posmForm.Campaign.name,
              [posmForm.Campaign.startDate, posmForm.Campaign.endDate],
              posmForm.createdBy,
              posmForm.stores,
            );
            td.formatTime();
            return td;
          });
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    routeCreatePosmForm() {
      this.$router.push({
        name: 'posm-form-create',
      });
    },
    routeImportExcel() {
      this.$router.push({
        name: 'posm-form-import-excel',
      });
    },
    assignFilter() {
      this.apiParams.searchByCampaign = this.searchByCampaignInput;
      this.fetchPOSMForms(this.apiParams);
    },
    assignApiParams() {
      this.apiParams = Object.assign(
        {},
        {
          limit: convertToNumber({
            value: this.$route.query.limit,
            defaultValue: 10,
          }),
          page: convertToNumber({
            value: this.$route.query.page,
            defaultValue: 1,
          }),
          searchByCampaign: this.$route.query.searchByCampaign
            ? this.$route.query.searchByCampaign
            : '',
        },
      );
    },
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Quản lý Form Posm' }]);
    if (this.$route.query) this.assignApiParams();
    this.fetchPOSMForms();
  },
};
</script>
<style scoped>
p {
  margin-bottom: 0;
}

.icon-font-size {
  font-size: 1rem;
}


.rubbish-icon-color {
  color: #d33;
}
</style>
